import React, { lazy, Suspense } from "react";
import { useRoutes, Navigate, useLocation  } from "react-router-dom";


const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
      <Suspense>
          <Component {...props} />
      </Suspense>
  );
};

export default function RoutesComponent() {
  const routes = useRoutes([
    { 
      path: '/',
      element: <Layout />,
      children: [
        { element: <Home replace />, index: true },
        { path: 'aboutus', element: <AboutUs /> },
        { path: 'authors', element: <Authors /> },
        { path: 'categories', element: <Categories /> },
        { path: 'quoteoftheday', element: <QuoteOfTheDay /> },
        { path: 'authors/:id', element: <Quotes /> },
        { path: 'categories/:id', element: <CategoriesById /> },
        { path: 'pictureQuotes', element: <PictureQuotes />},
        { path: 'privacy', element: <PrivacyPolicy /> },
        { path: 'contactus', element: <ContactUs /> },
      
      ]
    },
  ]);

  return routes;
}


// components
const Layout = Loadable(lazy(() => import("../layout/Layout")));
const Home =  Loadable(lazy(() => import("../components/Home")));
const AboutUs = Loadable(lazy(() => import("../components/Aboutus")));
const Authors = Loadable(lazy(() => import("../components/authors/Authors")));
const Categories = Loadable(lazy(() => import("../components/categories/Categories")));
const QuoteOfTheDay = Loadable(lazy(() => import("../components/quoteOfTheDay/QuoteOfTheDay")));
const Quotes = Loadable(lazy(() => import("../components/authors/AuthorsById")));
const CategoriesById = Loadable(lazy(() => import("../components/categories/CategoriesById")));
const PictureQuotes = Loadable(lazy(() => import ("../components/pictureQuote/PictureQuotes")));
const PrivacyPolicy = Loadable(lazy(() => import("../components/PrivacyPolicy")));
const ContactUs = Loadable(lazy(() => import("../components/Contactus")));
