import React, { useState } from "react";
import { BrowserRouter as Router, Outlet } from "react-router-dom";
import RoutesComponent from "./route/Routes";
import AdsCompo from "./components/advertisementads/AdsCompo";


function App() {
  const [clickedType, setClickedType] = useState(null);
   const handleTypeClick = (type) => {
    setClickedType(type);
  };

  return (
    <>
     <RoutesComponent />
      {/* <AdsCompo adUnitId="ca-app-pub-7842443993349666/9258986815"/> */}
    </>
  );
}

export default App;
