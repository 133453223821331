import React, { useEffect } from 'react';
import {Adsense} from '@ctrl/react-adsense';

const AdsCompo = () => {
 return (
<Adsense
  client="ca-pub-9809524747400798"
  slot="5956116089"
  adTest='of'
  // style={{ display: 'block' }}
  // layout="in-article"
  // format="fluid"
 />
      );
};

export default AdsCompo;
